import React from 'react';

const ZenChef = () => {
  return (
    <iframe
      src='https://bookings.zenchef.com/results?rid=366728&pid=1001'
      width='100%'
      height='630'
      style={{
        display: 'block',
        margin: '0 auto',
        maxWidth: '600px',
        height: '630px',
      }}
      title='Booking Results'
    ></iframe>
  );
};

export default ZenChef;
