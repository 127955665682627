import images from './images';

const nvmenu_nl = [
  {
    itemName: 'Amuse',
    tranliteration: '',
    translation: '',
    meaning: '',
    category: 'compliment1',
    course: '3, 5, 7',
    ingredients: '',
    origin: '',
    noOfIngredients: 0,
    noOfComponents: 0,
    subitemName1: '',
    ingredients1: '',
    noOfIngredients1: 0,
    noOfComponents1: 0,
    origin1: '',
    subitemName2: '',
    ingredients2: '',
    noOfIngredients2: 0,
    noOfComponents2: 0,
    origin2: '',
  },
  {
    itemName: 'Kozhi',
    tranliteration: '',
    translation: 'Kip',
    meaning: '[Tamil-taalnaam voor Kip]',
    category: 'starter',
    course: '3, 5, 7',
    ingredients:
      'Kip, Kokosnoot, Citroen rijst, Curry bladeren schuim, Parel ui',
    origin: 'Uit Pallipalayam Erode',
    noOfIngredients: 25,
    noOfComponents: 4,
    subitemName1: '',
    ingredients1: '',
    noOfIngredients1: 0,
    noOfComponents1: 0,
    origin1: '',
    subitemName2: '',
    ingredients2: '',
    noOfIngredients2: 0,
    noOfComponents2: 0,
    origin2: '',
  },
  {
    itemName: 'Cana na lota',
    tranliteration: '',
    translation: '',
    meaning: '[Gujarati-taalnaam voor linzen genaamd "Bengal Gram"]',
    category: 'starter',
    course: '5, 7',
    ingredients:
      'Vurige apple, Biet poeder, Munt, Tomaat, Curry bladeren, Panch phoron',
    origin: 'Uit Gujrat',
    noOfIngredients: 28,
    noOfComponents: 6,
    subitemName1: '',
    ingredients1: '',
    origin1: '',
    noOfIngredients1: 0,
    noOfComponents1: 0,
    subitemName2: '',
    ingredients2: '',
    noOfIngredients2: 0,
    noOfComponents2: 0,
    origin2: '',
  },
  {
    itemName: "Let's Chaat",
    tranliteration: '',
    translation: '',
    meaning: '',
    category: 'starter',
    course: '7',
    ingredients: '',
    noOfIngredients: 0,
    noOfComponents: 0,
    subitemName1: 'Minum',
    sI1tranliteration: '',
    sI1translation: '',
    sI1meaning: '[Malayalam-taalnaam voor Vis]',
    ingredients1:
      'Yoghurt, Dadels, Salamon vis puttu, Munt, Crunchy rijst, Horse gram spruiten',
    origin1: 'Uit Salem & Kerala',
    noOfIngredients1: 36,
    noOfComponents1: 7,
    subitemName2: 'Sooji',
    sI2tranliteration: '',
    sI2translation: '',
    sI2meaning: '[Algemeen Indiaas-taalnaam voor Griesmeel]',
    ingredients2:
      'Masala salamon, Dadels, Munt, Tangy pani, Kara bhoondhi, Moth bonen spruiten',
    origin2: 'Van het oude koninkrijk Magadha (Bihar) & Kerala',
    noOfIngredients2: 39,
    noOfComponents2: 7,
  },
  {
    itemName: 'Palate cleanser',
    tranliteration: '',
    translation: '',
    meaning: '',
    category: 'compliment2',
    course: '7',
    ingredients: '',
    origin: '',
    noOfIngredients: 0,
    noOfComponents: 0,
    subitemName1: '',
    ingredients1: '',
    origin1: '',
    noOfIngredients1: 0,
    noOfComponents1: 0,
    subitemName2: '',
    ingredients2: '',
    noOfIngredients2: 0,
    noOfComponents2: 0,
    origin2: '',
  },
  {
    itemName: 'Semmari aadu',
    tranliteration: '',
    translation: '',
    meaning: '[Tamil-taalnaam voor Lam]',
    category: 'main',
    course: '3, 5, 7',
    ingredients:
      'Ambur lam biriyani geheid, Lucknowi lamsvlees, Saffraan, Kewra pani, Granaatappel',
    origin: 'Uit Ambur-Tamilnadu & Lucknow',
    noOfIngredients: 37,
    noOfComponents: 7,
    subitemName1: '',
    ingredients1: '',
    origin1: '',
    noOfIngredients1: 0,
    noOfComponents1: 0,
    subitemName2: '',
    ingredients2: '',
    noOfIngredients2: 0,
    noOfComponents2: 0,
    origin2: '',
  },
  {
    itemName: 'Cinri',
    tranliteration: '',
    translation: '',
    meaning: '[Bengaals-taalnaam voor Garnalen]',
    category: 'main',
    course: '7',
    ingredients:
      'Kerala appam, Bengali garnalen romige saus, Rauwe banaan, Kokosnoot',
    origin: 'Uit Kerala & Bengal',
    noOfIngredients: 24,
    noOfComponents: 4,
    subitemName1: '',
    ingredients1: '',
    origin1: '',
    noOfIngredients1: 0,
    noOfComponents1: 0,
    subitemName2: '',
    ingredients2: '',
    noOfIngredients2: 0,
    noOfComponents2: 0,
    origin2: '',
  },
  {
    itemName: 'Kapi',
    tranliteration: '',
    translation: '',
    meaning: '[Kannada-taalnaam voor koffie]',
    category: 'desserts',
    course: '3, 5, 7',
    ingredients:
      '23K bladgoud, Kesari bath, Amandelen, Koffie saus, Zuccini, Zaden tuile, Ghee',
    origin: 'Uit Karnataka',
    noOfIngredients: 22,
    noOfComponents: 8,
    subitemName1: '',
    ingredients1: '',
    origin1: '',
    noOfIngredients1: 0,
    noOfComponents1: 0,
    subitemName2: '',
    ingredients2: '',
    noOfIngredients2: 0,
    noOfComponents2: 0,
    origin2: '',
  },
  {
    itemName: 'Muga di dala',
    tranliteration: '',
    translation: '',
    meaning: '[Punjabi-taalnaam voor linzen genaamd "Moong Dal"]',
    category: 'desserts',
    course: '5, 7',
    ingredients: 'Moong dal Crème brûlée, Kokosnoot, Ghee',
    origin: 'Uit Punjab',
    noOfIngredients: 9,
    noOfComponents: 2,
    subitemName1: '',
    ingredients1: '',
    origin1: '',
    noOfIngredients1: 0,
    noOfComponents1: 0,
    subitemName2: '',
    ingredients2: '',
    noOfIngredients2: 0,
    noOfComponents2: 0,
    origin2: '',
  },
  {
    itemName: 'Post meal treat',
    tranliteration: '',
    translation: '',
    meaning: '',
    category: 'compliment3',
    course: '7',
    ingredients: '',
    origin: '',
    subitemName1: '',
    ingredients1: '',
    origin1: '',
    noOfIngredients1: 0,
    noOfComponents1: 0,
    subitemName2: '',
    ingredients2: '',
    noOfIngredients2: 0,
    noOfComponents2: 0,
    origin2: '',
  },
];

const vmenu_nl = [
  {
    itemName: 'Amuse',
    course: '3, 5, 7',
    category: 'compliment1',
  },
  {
    itemName: 'Bhaji',
    course: '3, 5, 7',
    tranliteration: '',
    translation: '',
    meaning: '[Marathi-taalnaam voor Groenten]',
    ingredients:
      'Brittle Maple, Spons pav, Uienringen, Kasuri methi, Limonadesiroop',
    origin: 'Uit Mumbai',
    noOfIngredients: 44,
    noOfComponents: 4,
    category: 'starter',
  },
  {
    itemName: 'Cana na lota',
    tranliteration: '',
    translation: '',
    meaning: '[Gujarati-taalnaam voor linzen genaamd "Bengal Gram"]',
    category: 'starter',
    course: '5, 7',
    ingredients:
      'Vurige apple, Biet poeder, Munt, Tomaat, Curry bladeren, Panch phoron',
    origin: 'Uit Gujrat',
    noOfIngredients: 28,
    noOfComponents: 6,
    subitemName1: '',
    ingredients1: '',
    origin1: '',
    noOfIngredients1: 0,
    noOfComponents1: 0,
    subitemName2: '',
    ingredients2: '',
    noOfIngredients2: 0,
    noOfComponents2: 0,
    origin2: '',
  },
  {
    itemName: "Let's Chaat",
    tranliteration: '',
    translation: '',
    meaning: '',
    category: 'starter',
    course: '7',
    ingredients: '',
    noOfIngredients: 0,
    noOfComponents: 0,
    subitemName1: 'Arisi mavu',
    sI1tranliteration: '',
    sI1translation: '',
    sI1meaning: '[Tamil-taalnaam voor Rijstmeel]',
    ingredients1:
      'Yoghurt, Dadels, Aardappel, Munt, Umami poeder, Horsegram spruiten',
    origin1: 'Uit Salem',
    noOfIngredients1: 32,
    noOfComponents1: 7,
    subitemName2: 'Sooji',
    sI2tranliteration: '',
    sI2translation: '',
    sI2meaning: '[Algemeen Indiaas-taalnaam voor Griesmeel]',
    ingredients2:
      'Aardappel, Dadels, Munt, Tangy pani, Kara bhoondhi, Moth bonen spruiten, Imli',
    origin2: 'Van het oude koninkrijk Magadha  (Bihar)',
    noOfIngredients2: 33,
    noOfComponents2: 6,
  },
  {
    itemName: 'Palate cleanser',
    course: '7',
    category: 'compliment2',
  },
  {
    itemName: 'Annam',
    tranliteration: '',
    translation: '',
    meaning: '[Telugu-taalnaam voor Gekookte Rijst]',
    course: '3, 5, 7',
    subitemName1: 'Parrupu',
    sI1tranliteration: '',
    sI1translation: '',
    sI1meaning: '[Tamil-taalnaam voor linzen genaamd "Toor Dal"]',
    ingredients1:
      'Eetbaar drumstick, Sambar schuim, Parel ui, Wortel, Drumstick, Curry bladeren',
    subitemName2: 'Tamotalu',
    sI2tranliteration: '',
    sI2translation: '',
    sI2meaning: '[Telugu taalnaam voor tomaten]',
    ingredients2: 'Zwarte rijst, Gevangen rasam, Gele rijstcrunch, Neem bloem',
    subitemName3: 'Thayir',
    ingredients3:
      'Curd, Milk shower, Curry bladeren, Tangy piquant kovaikai chips, Gunpowder',
    origin: 'Uit Tamilnadu & Andhra',
    noOfIngredients2: 60,
    noOfComponents2: 19,
    category: 'main',
  },
  {
    itemName: 'Paneer',
    course: '7',
    ingredients:
      'Khoba roti, Kasuri methi, Hyderabadi romige saus, Haldi roots, Groene erwten, Ghee shower',
    origin: 'Uit Rajasthan & Hyderabad',
    noOfIngredients: 28,
    noOfComponents: 4,
    category: 'main',
  },
  {
    itemName: 'Kapi',
    course: '3, 5, 7',
    ingredients:
      'Gouden blad, Kesari bath, Badam, Koffie saus, Zuccini, Kalonji-Sesame zaden, Tuile, Ghee',
    origin: 'Uit Karnataka',
    noOfIngredients: 22,
    noOfComponents: 8,
    category: 'desserts',
  },
  {
    itemName: 'Muga di dala',
    course: '5, 7',
    ingredients: 'Moong dal Crème brûlée, Kokosnoot, Ghee',
    origin: 'Uit Punjab',
    noOfIngredients: 22,
    noOfComponents: 8,
    category: 'desserts',
  },
  {
    itemName: 'Post meal treat',
    course: '7',
    category: 'compliment3',
  },
];

const wines = [
  {
    title: 'Chapel Hill Shiraz',
    price: '$56',
    tags: 'AU | Bottle',
  },
  {
    title: 'Catena Malbee',
    price: '$59',
    tags: 'AU | Bottle',
  },
  {
    title: 'La Vieillw Rose',
    price: '$44',
    tags: 'FR | 750 ml',
  },
  {
    title: 'Rhino Pale Ale',
    price: '$31',
    tags: 'CA | 750 ml',
  },
  {
    title: 'Irish Guinness',
    price: '$26',
    tags: 'IE | 750 ml',
  },
];

const cocktails = [
  {
    title: 'Aperol Sprtiz',
    price: '$20',
    tags: 'Aperol | Villa Marchesi prosecco | soda | 30 ml',
  },
  {
    title: "Dark 'N' Stormy",
    price: '$16',
    tags: 'Dark rum | Ginger beer | Slice of lime',
  },
  {
    title: 'Daiquiri',
    price: '$10',
    tags: 'Rum | Citrus juice | Sugar',
  },
  {
    title: 'Old Fashioned',
    price: '$31',
    tags: 'Bourbon | Brown sugar | Angostura Bitters',
  },
  {
    title: 'Negroni',
    price: '$26',
    tags: 'Gin | Sweet Vermouth | Campari | Orange garnish',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Bib Gourmond',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
];

export default { wines, cocktails, awards, nvmenu_nl, vmenu_nl };
