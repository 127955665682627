import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { data, images } from '../../constants';
import './Contact.css';

// const Contact = () =>
function Contact() {
  const waimgurl = images.WhatsAppButtonGreenSmall;
  return (
    <div
      className='app_Contact_bg app_Contact_wrapper section__padding flex__center'
      id='contact'
    >
      <div className='app_Contact-title'>
        <h1
          className='headtext__cormorant'
          style={{
            fontWeight: '1000',
            fontSize: '2.5rem',
            marginBottom: '.25rem',
          }}
        >
          Contact
        </h1>
      </div>

      <div className='app_Contact-menu'>
        <div className='app_Contact-menu_wine  flex__start'>
          <h1
            className='headtext__cormorant'
            style={{
              fontSize: '1.5rem',
              marginTop: '.5rem',
              marginBottom: '.25rem',
            }}
            translate='no'
          >
            Wit Aaranya
          </h1>
          <p className='p__opensans' translate='no'>
            1, Oversneslaan, 2610-Wilrijk, Antwerpen
          </p>
          <p
            className='p__opensans'
            translate='no'
            style={{ marginTop: '.5rem' }}
          >
            <a href='tel:+32468013110' rel='noopener noreferrer'>
              +32468013110
            </a>
          </p>
          <p
            className='p__opensans'
            translate='no'
            style={{ marginTop: '.5rem' }}
          >
            <a href='mailto: info@witaaranya.be' rel='noopener noreferrer'>
              info@WitAaranya.be
            </a>
          </p>
          <p
            className='p__cormorant'
            style={{
              fontSize: '1.25rem',
              color: 'var(--color-golden)',
              marginTop: '2rem',
              marginBottom: '.5rem',
            }}
          >
            Openingstijden
          </p>
          <p className='p__opensans'>Vrijdag - Zaterdag: 18:00 tot 22:30</p>
        </div>

        <div
          className='app_Contact-dash'
          style={{
            marginTop: '1rem',
          }}
        />

        {/* <div className='app_Contact-menu_wine  flex__start'>
          <h1
            className='headtext__cormorant'
            style={{
              fontSize: '2rem',
              marginTop: '1rem',
              marginBottom: '.25rem',
            }}
            translate='no'
          >
            Reserveer tafel
          </h1>
          <p
            className='p__cormorant '
            style={{
              fontWeight: '1000',
              color: 'var(--color-gray)',
              marginTop: '2rem',
              marginBottom: '.25rem',
            }}
          >
            <p>
              <a
                href='https://tableagent.com/antwerp/wit-aaranya/'
                className='box'
                target='_blank'
                rel='noopener noreferrer'
                translate='no'
              >
                Reserveren
              </a>
            </p>
          </p>
        </div> */}
        <div
          className='app_Contact-dash'
          style={{
            marginTop: '1rem',
          }}
        />
        <div className='app_Contact-menu_wine  flex__start'>
          <h1
            className='headtext__cormorant'
            style={{
              fontSize: '1.5rem',
              marginTop: '1rem',
              marginBottom: '.25rem',
            }}
            translate='no'
          >
            Corporate/Groep Reservering
          </h1>
          <p
            className='p__opensans'
            style={{
              fontSize: '.85rem',
            }}
          >
            Welkom in ons fine dining restaurant! We zijn verheugd om corporate
            of groepsreserveringen aan te bieden voor feesten tussen 10 en 26
            personen, die een perfecte setting nodig hebben voor speciale
            gelegenheden en bijeenkomsten die een vleugje elegantie en
            verfijning vereisen.
          </p>
          <br></br>
          <p
            className='p__opensans'
            style={{
              fontSize: '.85rem',
            }}
          >
            Ons voortreffelijke menu wordt bereid met uitzonderlijke aandacht
            voor detail. We zijn er trots op onze gerechten te presenteren met
            een prachtige balans van smaken en texturen die zeker indruk zullen
            maken op zelfs de meest veeleisende fijnproevers.
          </p>
          <br></br>
          <p
            className='p__opensans'
            style={{
              fontSize: '.85rem',
            }}
          >
            We doen er alles aan om u en uw gasten een onberispelijke service te
            bieden tijdens uw maaltijd op ELKE DAG van de WEEK. We zullen nauw
            met u samenwerken om uw eetervaring te personaliseren, inclusief
            menuselectie, wijnarrangementen en eventuele speciale verzoeken.
          </p>
          <br></br>
          <p
            className='p__opensans'
            style={{
              fontSize: '.85rem',
            }}
          >
            Uw groepsreservering boeken is eenvoudig!
          </p>

          <p
            className='p__opensans'
            translate='no'
            style={{ marginTop: '.5rem' }}
          >
            <a
              aria-label='Chat on WhatsApp'
              href='https://wa.me/32468013110'
              target='_blank'
            >
              <img
                src={waimgurl}
                alt='Chat on WhatsApp'
                className='app_Contact-Group_img'
              ></img>
            </a>
          </p>
          <p
            className='p__opensans'
            translate='no'
            style={{ marginTop: '.5rem' }}
          >
            OR
          </p>
          <p
            className='p__opensans'
            translate='no'
            style={{ marginTop: '.5rem' }}
          >
            <a href='tel:+32468013110' rel='noopener noreferrer'>
              +32468013110
            </a>
          </p>
          <p
            className='p__opensans'
            translate='no'
            style={{ marginTop: '.5rem' }}
          >
            OR
          </p>
          <p
            className='p__opensans'
            translate='no'
            style={{ marginTop: '.5rem' }}
          >
            <a
              href='mailto: info@witaaranya.be?subject=GroepReservering%20[YOUR%20COMPANY%20OF%20NAME]'
              rel='noopener noreferrer'
            >
              E-Mail ons: info@WitAaranya.be
            </a>
          </p>
        </div>
      </div>

      <div className='app_Contact-dash' />
    </div>
  );
}

export default Contact;
