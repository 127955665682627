import bg from '../assets/images/bg.jpg';
import LogoWithName from '../assets/images/LogoWithName.png';
import Divider from '../assets/images/Divider.png';
import WhatsAppButtonGreenSmall from '../assets/images/WhatsAppButtonGreenSmall.png';
import MenuPageImage01 from '../assets/images/MenuPageImage01.jpg';
import bgPortrait from '../assets/images/bg_portrait.jpg';

export default {
  bg,
  LogoWithName,
  Divider,
  WhatsAppButtonGreenSmall,
  MenuPageImage01,
  bgPortrait,
};
