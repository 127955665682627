import React from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';
import useExternalScripts from '../../hooks/useExternalScripts';

import './Header.css';

// function Header() {
//   const headerStyle = {
//     backgroundImage: "url('../assets/images/bg.jpg')",
//     backgroundSize: 'cover',
//     backgroundPosition: 'center center',
//     backgroundRepeat: 'no-repeat',
//   };

//   return (
//     <div>
//       <div className='app__header app__wrapper'>

//           <div className='app__header-logo'>
//             <img src={images.LogoWithName} alt='app logo'></img>
//           </div>

//           <div className='app__header app__wrapper section__padding' id='home'>
//             <div className='app__wrapper_info'>
//               <h1 className='app__header-h2'>trail naar culinaire hemel...</h1>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

import backgroundImage from '../../assets/images/bg.jpg';

function Header() {
  // const containerStyle = {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'flex-start',
  //   alignItems: 'flex-start',
  //   backgroundImage: `url(${backgroundImage})`,
  //   backgroundRepeat: 'no-repeat',
  //   backgroundSize: 'cover',
  //   height: '100vh',
  // };
  const handleClick = () => {
    // Open a URL in a new tab/window
    window.open(
      'https://bookings.zenchef.com/results?rid=366728&pid=1001',
      '_blank'
    );
  };

  return (
    // <div className='container' style={containerStyle} id='home'>
    <div className='container' id='home'>
      <div className='app__header-logo'>
        <img src={images.LogoWithName} alt='Wit Aaranya'></img>
      </div>
      <div className='app__wrapper_info' style={{ justifyContent: 'end' }}>
        <h1
          className='app__header_First-h1'
          style={{
            textShadow:
              '-.05rem -.05rem 0 #3c3c3c, .05rem -.05rem 0 #3c3c3c, -.05rem .05rem 0 #3c3c3c, .05rem .05rem 0 #3c3c3c, .3rem .3rem .2rem #3c3c3c',
          }}
        >
          Eerste Indiase gebaseerd Fine Dining restaurant in de Europese Unie
        </h1>
        <h1
          className='app__header-h1'
          style={{
            textShadow:
              '-.05rem -.05rem 0 #3c3c3c, .05rem -.05rem 0 #3c3c3c, -.05rem .05rem 0 #3c3c3c, .05rem .05rem 0 #3c3c3c, .3rem .3rem .2rem #3c3c3c',
          }}
        >
          . . . trail naar culinaire hemel . . .
        </h1>
        {/* <p
          className='p__cormorant'
          style={{
            fontWeight: '1000',
            color: 'var(--color-black)',
            marginLeft: '3rem',
            marginBottom: '3rem',
          }}
        >
          <p>
            <a
              href='https://tableagent.com/antwerp/wit-aaranya/'
              className='box'
              target='_blank'
              rel='noopener noreferrer'
            >
              Reserveren
            </a>
          </p>
        </p> */}
        {/* useExternalScripts("https://sdk.zenchef.com/v1/sdk.min.js")
        <div class='zc-widget-config' data-restaurant='366728'></div> */}
      </div>
    </div>
  );
}

export default Header;
