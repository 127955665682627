import React from 'react';
import './Jobs.css';
import { SubHeading } from '../../components';
import { images } from '../../constants';

const Jobs = () => (
  <div className='app__jobs app__wrapper section__padding' id='jobs'>
    <div className='app__wrapper_info'>
      {/* <SubHeading title='Contact' /> */}
      <h3
        className='headtext__cormorant'
        style={{
          fontWeight: '1000',
          fontSize: '3rem',

          marginBottom: '1rem',
        }}
      >
        Vacature
      </h3>

      <div className='app__wrapper-content'>
        <p
          className='p__opensans'
          style={{
            color: '#DCCA87',
            marginTop: '3rem',
            marginBottom: '1rem',
            fontSize: '20px',
          }}
        >
          Vacature voor Restaurant Server
        </p>
        <p
          className='p__opensans'
          style={{ marginTop: '.5rem', marginBottom: '1rem' }}
        >
          Flexi- of studentwerkzoekenden{' '}
        </p>
        <p
          style={{
            fontFamily: 'var(--font-alt)',
            color: 'var(--color-white)',
            fontWeight: '350',
            letterSpacing: '0.04em',
            lineHeight: '28px',
            fontSize: '16px',
          }}
        >
          Wij zijn op zoek naar een Restaurant Server om ons team te komen
          versterken. Als server ben je verantwoordelijk voor het bieden van
          uitzonderlijke klantenservice, het opnemen van nauwkeurige
          bestellingen en het tijdig serveren van eten en drinken. Je werkt
          samen met het keukenpersoneel om ervoor te zorgen dat de bestellingen
          naar tevredenheid van de gasten worden bereid en dat de gastentafel en
          werkruimte schoon en georganiseerd zijn. De ideale kandidaat beschikt
          over uitstekende communicatieve en interpersoonlijke vaardigheden en
          kan in een snel veranderende omgeving werken. Als je een passie hebt
          voor het bieden van uitzonderlijke klantenservice, raden we je aan om
          te solliciteren op deze geweldige kans.
        </p>
        <p
          className='p__opensans'
          style={{ marginTop: '.5rem', marginBottom: '1rem' }}
        >
          Taal: Nederlands en Engels.
        </p>

        <p
          className='p__cormorant '
          style={{
            fontWeight: '1000',
            color: 'var(--color-gray)',
            marginTop: '3rem',
            marginBottom: '1rem',
          }}
        >
          <p>
            <a
              href='mailto: jobs@witaaranya.be'
              className='box'
              target='_blank'
              rel='noopener noreferrer'
            >
              Toepassen
            </a>
          </p>
        </p>
      </div>
    </div>
  </div>
);

export default Jobs;
