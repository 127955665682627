import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { data, images } from '../../constants';
import './Menu.css';
import MenuKaart from './../../assets/pdfs/MenuKaart.pdf';
import MenuCardEN from './../../assets/pdfs/MenuCard_EN.pdf';
import MenuCardJainEN from './../../assets/pdfs/MenuCardJain_EN.pdf';

//const SpecialMenu = () => (
function SpecialMenu() {
  const menuImgURL = images.MenuPageImage01;
  return (
    <div className='app__specialMenu flex__center section__padding' id='menu'>
      <div className='app__specialMenu-title'>
        <h1 className='headtext__cormorant'>Menu</h1>
      </div>

      <div className='app__specialMenu-menu'>
        <div className='app__specialMenu-menu_wine  flex__center'>
          <p className='p__opensans'>
            Ons menu viert de rijkdom en diversiteit van gerechten uit
            verschillende regio's en de tijdlijn van India. We brengen passie en
            creativiteit in elk gerecht, om een onvergetelijke eetervaring te
            creëren. Het biedt een symfonie van smaken die je zintuigen
            prikkelen en je naar nieuwe culinaire hoogten brengen. Laat ons u
            meenemen via de{' '}
            <span
              style={{
                color: 'var(--color-golden)',
                fontWeight: '500',
              }}
            >
              Trail naar de culinaire hemel.
            </span>
            <span style={{ color: 'var{--color-golden}' }}></span>
          </p>
          <div className='app__specialMenu-menu_img'>
            <img src={menuImgURL}></img>
          </div>
        </div>

        <div className='app__specialMenu-menu_wine  flex__center'>
          <p
            className='p__cormorant '
            style={{
              fontWeight: '1000',
              color: 'var(--color-gray)',
              marginTop: '2rem',
              marginBottom: '.25rem',
            }}
          >
            <p>
              <a
                href={MenuKaart}
                className='box'
                target='_blank'
                rel='noopener noreferrer'
                translate='no'
              >
                Menukaart
              </a>
            </p>
          </p>
          <p
            className='p__cormorant '
            style={{
              fontWeight: '1000',
              color: 'var(--color-gray)',
              marginTop: '2rem',
              marginBottom: '.25rem',
            }}
          >
            <p>
              <a
                href={MenuCardEN}
                className='box'
                target='_blank'
                rel='noopener noreferrer'
                translate='no'
              >
                Menu in English
              </a>
            </p>
          </p>
          <p
            className='p__cormorant '
            style={{
              fontWeight: '1000',
              color: 'var(--color-gray)',
              marginTop: '2rem',
              marginBottom: '.25rem',
              display: 'none',
            }}
          >
            <p>
              <a
                href={MenuCardJainEN}
                className='box'
                target='_blank'
                rel='noopener noreferrer'
                translate='no'
              >
                Jain menu in English
              </a>
            </p>
          </p>
        </div>
      </div>
      <div>
        <div className='app__specialMenu-dash' />
        <p
          className='app__specialMenu-menu_warning  flex__center p__cormorant'
          style={{
            fontWeight: '1000',
            color: 'var(--color-golden)',
          }}
        >
          Drankmenu
        </p>
        <br></br>
        <p className='app__specialMenu-menu_warning  flex__center'>
          verkrijgbaar bij Restaurant
        </p>
        <br></br>
        <p className='app__specialMenu-menu_warning  flex__center'>
          Wij serveren een selectie van wijnen die geschikt zijn voor het menu.
          Het menu Water, Cola, Sappen en Warme Dranken is ook beschikbaar.
        </p>
        <br></br>
        <div className='app__specialMenu-dash' />
      </div>
    </div>
  );
}

export default SpecialMenu;
