import React from 'react';
import {
  AboutUs,
  Chef,
  Contact,
  FindUs,
  Footer,
  Gallery,
  Header,
  Intro,
  Laurels,
  Menu,
  SpecialMenu,
  Jobs,
} from './container';
import { Navbar } from './components';
import { Footbar } from './components';
import './App.css';
import MarqueeCom from './components/MarqueeComp/MarqueeComp';

const App = () => {
  return (
    <div>
      <Navbar></Navbar>

      <Footbar></Footbar>
      {/* <MarqueeCom></MarqueeCom> */}
      <Header></Header>

      {/* <AboutUs></AboutUs> */}
      <Menu></Menu>
      <Contact></Contact>
      <Jobs></Jobs>
      {/* <Chef></Chef>
      <Intro></Intro>
      <Laurels></Laurels>
      <Gallery></Gallery>
      
      <Footer></Footer> */}
    </div>
  );
};

export default App;
