import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from '../../constants/images';

import './Footbar.css';
import ZenChef from '../ZenChef/ZenChef';

function Footbar() {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <div className='app__footbar'>
      <div className='app__footbar-smallscreen'>
        <button
          className='p__cormorant'
          style={{
            fontWeight: '1000',
            color: 'var(--color-black)',
            marginLeft: '3rem',
            marginBottom: '3rem',
          }}
          onClick={() => setToggleMenu(true)}
        >
          <a className='box' target='_blank' rel='noopener noreferrer'>
            Reserveren
          </a>
        </button>{' '}
        {toggleMenu && (
          <div className='app__navbar-smallscreen_overlay flex__center slide-bottom'>
            <div className='App'>
              <h1>Embedded Booking Iframe</h1>
              <ZenChef />
            </div>
            <MdOutlineRestaurantMenu
              fontSize={27}
              className='overlay__close'
              onClick={() => setToggleMenu(false)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Footbar;
