import React from 'react';
import Marquee from 'react-fast-marquee';

function MarqueeComp() {
  return (
    <div>
      <Marquee speed='100'>
        <div className='marquee__marquee'>
          **************** Klaar voor een nieuw trail naar de culinaire hemel,
          NIEUW MENU Bekijk het menu. *************************
        </div>
      </Marquee>
    </div>
  );
}

export default MarqueeComp;
